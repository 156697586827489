import React, { Fragment } from "react"

import { makeStyles } from "@material-ui/core/styles"

import withRoot from "../withRoot"
import AppFooter from "../views/AppFooter"

import BusinessHero from "../views/business/BusinessHero"

import SEO from "../components/SEO"

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  box: {
    padding: spacing(8, 0),
    [breakpoints.down("sm")]: {
      padding: spacing(4, 0),
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  contentContainer: {
    position: "relative",
    width: "60%",
  },
  iframeContainer: {
    width: "30%",
    position: "sticky",
    top: 100,
    height: 600,
  },
}))

function Index() {
  const classes = useStyles()
  return (
    <Fragment>
      <SEO
        title="GUPKA"
        description="Doing Stuff, And More"
        image="/socialpreview.png"
        pathname="/"
      />

      <BusinessHero />
    </Fragment>
  )
}

export default withRoot(Index)
