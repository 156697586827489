import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"

import ExternalLink from "@material-ui/core/Link"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import Button from "@material-ui/core/Button"

import Typography from "./Typography"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    width: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    borderLeft: `3px solid ${theme.palette.grey[400]}`,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    color: theme.palette.common.white,
  },
  rightLink: {
    fontSize: 12,
    color: theme.palette.grey[400],
    fontWeight: 400,
    margin: theme.spacing(0, 1),
    textDecoration: "none",
    textTransform: "uppercase",
  },
  noDecoration: {
    textDecoration: "none",
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  activeLink: {
    fontWeight: 800,
    fontSize: 12,
    color: theme.palette.common.white,
  },
}))

export default function PersistentDrawerRight({ open, handleDrawerClose }) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      className={clsx({ [classes.hide]: !open })}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.drawerHeader}>
        <IconButton color="inherit" onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider variant="middle" className={classes.divider} />
      <List>
        <ListItem>
          <Typography
            component={Link}
            color="inherit"
            variant="h6"
            underline="none"
            className={classes.rightLink}
            activeClassName={classes.activeLink}
            to="/"
          >
            Home
          </Typography>
        </ListItem>
        <ListItem>
          <Typography
            component={Link}
            color="inherit"
            variant="h6"
            underline="none"
            className={classes.rightLink}
            activeClassName={classes.activeLink}
            to="/onboarding"
          >
            Help Center
          </Typography>
        </ListItem>
      </List>
      <ListItem>
        <Typography
          component={Link}
          color="inherit"
          variant="h6"
          underline="none"
          className={classes.rightLink}
          activeClassName={classes.activeLink}
          to="/pricing"
        >
          Pricing
        </Typography>
      </ListItem>
      <List>
        <ListItem>
          <ExternalLink
            color="inherit"
            variant="h6"
            underline="none"
            className={classes.noDecoration}
            href="https://platform.capssion.com/login/"
            target="_blank"
          >
            <Button
              variant="contained"
              size="small"
              color="secondary"
              className={classes.button}
            >
              {"Log In"}
            </Button>
          </ExternalLink>
        </ListItem>
      </List>
    </Drawer>
  )
}
