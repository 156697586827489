import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Hidden, Container } from "@material-ui/core"

import Typography from "../components/Typography"

import facebook from "../images/socials/logo_facebook.svg"
import instagram from "../images/socials/logo_instagram.svg"
import twitter from "../images/socials/logo_twitter.svg"
import linkedin from "../images/socials/logo_linkedin.svg"

import capssion_black from "../images/logo/capssion_black.svg"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
  container: {
    padding: theme.spacing(8, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  socialContainer: {
    textAlign: "center",
  },
  iconsContainer: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  linksContainer: {
    textAlign: "center",
  },
  link: {
    margin: theme.spacing(0, 1, 1, 1),
    cursor: "pointer",
    fontSize: 12,
    color: theme.palette.grey[800],
    fontWeight: 400,
    textDecoration: "none",
    textTransform: "uppercase",
  },

  logoContainer: {
    display: "flex",
    margin: "0 auto",
  },
  logo: {
    width: 100,
    padding: theme.spacing(4, 0),
  },
  divider: {
    margin: theme.spacing(4, 0),
    display: "block",
    backgroundColor: theme.palette.grey[400],
    height: 1,
    width: 100,
  },
}))

export default function AppFooter() {
  const classes = useStyles()

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Hidden smDown>
          <div className={classes.socialContainer}>
            <div className={classes.iconsContainer}>
              <a
                href="https://www.instagram.com/capssion/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={instagram} alt="Instagram" className={classes.icon} />
              </a>
              <a
                href="https://www.facebook.com/capssion/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={facebook} alt="Facebook" className={classes.icon} />
              </a>
              <a
                href="https://www.twitter.com/capssion"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={twitter} alt="Twitter" className={classes.icon} />
              </a>
              <a
                href="https://www.linkedin.com/company/capssion/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={linkedin} alt="Linkedin" className={classes.icon} />
              </a>
            </div>
            <div className={classes.linksContainer}>
              <a
                href="https://www.capssion.com/trust-center/"
                target="_blank"
                className={classes.link}
              >
                Legal
              </a>
            </div>
            <Typography align="center" variant="caption">
              © 2017 - 2020 Capssion
            </Typography>
          </div>
          <div className={classes.divider} />
          <div className={classes.logoContainer}>
            <img
              src={capssion_black}
              alt="capssion logo"
              className={classes.logo}
            />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.socialContainer}>
            <div className={classes.iconsContainer}>
              <a
                href="https://www.instagram.com/capssion/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={instagram} alt="Instagram" className={classes.icon} />
              </a>
              <a
                href="https://www.facebook.com/capssion/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={facebook} alt="Facebook" className={classes.icon} />
              </a>
              <a
                href="https://www.twitter.com/capssion"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={twitter} alt="Twitter" className={classes.icon} />
              </a>
              <a
                href="https://www.linkedin.com/company/capssion/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                <img src={linkedin} alt="Linkedin" className={classes.icon} />
              </a>
            </div>
            <div className={classes.linksContainer}>
              <a
                href="https://www.capssion.com/trust-center/"
                target="_blank"
                className={classes.link}
              >
                Legal
              </a>
            </div>
            <Typography align="center" variant="caption">
              © 2017 - 2020 Capssion
            </Typography>
          </div>
          <div className={classes.divider} />
          <div className={classes.logoContainer}>
            <img
              src={capssion_black}
              alt="capssion logo"
              className={classes.logo}
            />
          </div>
        </Hidden>
      </Container>
    </Typography>
  )
}
