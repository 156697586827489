import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"

import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import AppBar from "../components/AppBar"
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar"

import gupka_white from "../images/logo/gupka_white.svg"

const styles = theme => ({
  title: {
    fontSize: 20,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  left: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logoContainer: {
    display: "flex",
  },
  logo: {
    height: 24,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  rightLink: {
    fontSize: 12,
    color: theme.palette.grey[400],
    fontWeight: 400,
    margin: theme.spacing(0, 1),
    textDecoration: "none",
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.grey[600],
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  separator: {
    margin: theme.spacing(0, 1),
  },
  button: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  noDecoration: {
    textDecoration: "none",
  },
  activeLink: {
    fontWeight: 800,
    fontSize: 12,
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.grey[200],
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
})

function AppAppBar({ classes, handleDrawerOpen }) {
  return (
    <AppBar position="fixed">
      <Toolbar className={clsx(classes.toolbar, toolbarStyles.root)}>
        <div className={classes.left}>
          <Link to="/" className={classes.logoContainer}>
            <img src={gupka_white} className={classes.logo} />
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  )
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppAppBar)
