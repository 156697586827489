import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import { Box, Typography } from "@material-ui/core"

import bg1 from "../../images/background/bg1.jpg"

const styles = theme => ({
  /* MAIN SECTION */
  hero: {
    width: "100%",
    height: "800px",
    backgroundImage: `url(${bg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: theme.spacing(10, 10),
    color: "white",
    position: "relative",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      height: "600px",
      padding: theme.spacing(5, 5),
    },
  },
  heroMessage: {
    backgroundColor: "rgba(0,0,0, 0.4)",
    border: "5px solid white",
    width: "66%",
    padding: theme.spacing(5),
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      padding: theme.spacing(2, 4),
    },
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: "none",
    cursor: "pointer",
  },
})

function BusinessHero(props) {
  const { classes } = props

  return (
    <Fragment>
      <Box className={classes.hero}>
        <div className={classes.heroMessage}>
          <Typography variant="h2" align="center">
            Doing stuff,
          </Typography>
          <Typography variant="h2" align="center" gutterBottom>
            and more.
          </Typography>
          <Typography
            align="center"
            color="inherit"
            variant="body1"
            gutterBottom
          >
            main project →{" "}
            <a className={classes.link} href="https://www.capssion.com">
              capssion.com
            </a>
          </Typography>
        </div>
      </Box>
    </Fragment>
  )
}

BusinessHero.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BusinessHero)
