import React, { useState } from "react";
import clsx from "clsx";
import { ParallaxProvider } from "react-scroll-parallax";
import {Helmet} from "react-helmet";

import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import AppAppBar from "./views/AppAppBar";
import Drawer from "./components/drawer";
import theme from "./theme";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    maxWidth: "100%",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginTop: 64, //appBar position fixed
    [theme.breakpoints.up("sm")]: {
      marginTop: 70
    },
    marginRight: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }
}));

function withRoot(Component) {
  function WithRoot(props) {
    const classes = useStyles();
    const [drawerOpened, setDrawerOpened] = useState(false);
    return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <link rel="preload" href="https://platform.capssion.com/invitation/gsl" as="document"/>
        </Helmet>
        <ParallaxProvider>
          <CssBaseline />
          <div className={classes.root}>
            <AppAppBar handleDrawerOpen={() => setDrawerOpened(true)} />
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: drawerOpened
              })}
            >
              <Component {...props} />
            </main>
            <Drawer
              open={drawerOpened}
              handleDrawerClose={() => setDrawerOpened(false)}
            />
          </div>
        </ParallaxProvider>
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
